/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import "../../@core/scss/base/bootstrap-extended/include";
@import "../../@core/scss/base//components/include"; // Components includes


/////////////// Custom Media queries ///////////////
// @include media-breakpoint-up(lg) {
// 	.plr-130 {
// 		padding: 0 130px;
// 	}
// }
// @include media-breakpoint-down(lg) {
// 	.plr-130 {
// 		padding: 0 130px;
// 	}
// }

// @include media-breakpoint-down(md) {
// 	.plr-130 {
// 		padding: 0 130px;
// 	}
// 	.plr-50 {
// 		padding: 0 50px;
// 	}
// }

@include media-breakpoint-up(sm) {
  .plr-130 {
    padding: 0 100px;
  }

  .plr-50 {
    padding: 0 50px;
  }

  .recomm_heading {
    color: white;
    font-size: 32px;
    font-weight: 600;
    padding-bottom: 0px;
  }
  .mblScreen {
    height: 43px;
  }
  .d-c {
    display: contents !important;
  }
}

@include media-breakpoint-down(sm) {
  .plr-130 {
    padding: 0 0;
  }

  .plr-50 {
    padding: 0 0px;
  }

  .q-text {
    width: 300px !important;
  }

  .recomm_heading {
    color: white;
    font-size: 32px;
    font-weight: 600;
    padding-bottom: 0px;
  }

  .d-flex-block {
    display: block !important;
  }
  .d-g {
    display: grid !important;
  }
}

@include media-breakpoint-down(xl) {
  .plr-130 {
    padding: 0 0;
  }

  .plr-50 {
    padding: 0 0px;
  }

  .test-h-img {
    display: none;
  }

  .q-text {
    width: auto !important;
  }

  .recomm_heading {
    color: white;
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 0px;
  }
}
/* RecommendedClass.css */
.zoom-in-card {
  transition: transform 0.3s ease;
}

.zoom-in-card:hover {
  transform: scale(1.05);
}

.zoom-in-img {
  transition: transform 0.3s ease;
}

.zoom-in-img:hover {
  transform: scale(1.05);
}
.zoom-in {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}
//
/* CSS for zoom effect */
.zoom-effect {
  transition: transform 0.3s ease;
}

.zoom-effect:hover {
  transform: scale(1.1); /* Increase the value to zoom in more */
}
//

//card story-----------start
.card-content {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 20px;
}

.author-col {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.author-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.author-name {
  margin-top: 10px;
  margin-bottom: 0;
}
.story-video {
  width: 500px;
  height: 250px;
  border-radius: 10px;
  object-fit: cover;
}
.delete-button {
  position: absolute;
  top: 10px;
  right: 10px;
  // z-index: 999;
}
.edit-button {
  position: absolute;
  top: 10px;
  right: 70px;
  // z-index: 999;
}

.image-col {
  
  justify-content: center;
  align-items: center;
}

.story-image {
  width: 500px;
  height: 250px;
  border-radius: 10px;
  object-fit: cover;
}

.details-col {
  padding-right: 20px;
  display: flex;
  flex-direction: column;
}

.story-title {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: bold;
}

.story-description {
  margin-bottom: 0;
  font-size: 14px;
}
//


.flipinx-animation {
  transform: scaleX(-1);
  transition: transform 0.3s ease;
}
//

.swiper-button-prev, .swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: #333;
  color: #fff;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.swiper-button-next {
  background-color: #ffffff; /* Change this to your desired background color */
  color: #130101; /* Change this to your desired text color */
  border-radius: 50%; /* Make it a circle */
  padding: 10px; /* Adjust the padding as needed */
  
}

.swiper-button-prev {
  background-color: #ffffff; /* Change this to your desired background color */
  color: #130101; /* Change this to your desired text color */
  border-radius: 50%; /* Make it a circle */
  padding: 10px; /* Adjust the padding as needed */
  
}
//welcome page
.welcome-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 
}

.fade-in {
  animation: fadeIn 2.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.welcome-message {
  font-size: 24px;
  margin-bottom: 20px;
}

.next-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  font-size: 18px;
}
.question-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.question-message {
  font-size: 24px;
  margin-bottom: 20px;
}
//thirdscreen
.meditation-reason-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.question-message {
  font-size: 24px;
  margin-bottom: 20px;
}

.response-input {
  width: 100%;
  height: 150px;
  font-size: 16px;
  margin-bottom: 20px;
  padding: 10px;
}

.small-text {
  font-size: 12px;
  margin-bottom: 20px;
  text-align: center;
}

.next-button {
  padding: 10px 20px;
  font-size: 18px;
}

.next-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.serene-moment-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.question-message {
  font-size: 24px;
  margin-bottom: 20px;
}

.response-input {
  width: 100%;
  height: 150px;
  font-size: 16px;
  margin-bottom: 20px;
  padding: 10px;
}

.small-text {
  font-size: 12px;
  margin-bottom: 20px;
  text-align: center;
}

.next-button {
  padding: 10px 20px;
  font-size: 18px;
}

.next-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.daily-routine-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.question-message {
  font-size: 24px;
  margin-bottom: 20px;
}

.routine-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.routine-button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.routine-button.selected {
  background-color: #4caf50;
  color: white;
  border: 1px solid #4caf50;
}

.next-button {
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  border: 1px solid #4caf50;
  border-radius: 5px;
}

.next-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.stress-impact-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.question-message {
  font-size: 24px;
  margin-bottom: 20px;
}

.options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.option-button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.option-button.selected {
  background-color: #4caf50;
  color: white;
  border: 1px solid #4caf50;
}

.next-button {
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  border: 1px solid #4caf50;
  border-radius: 5px;
}

.next-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.final-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.final-message {
  font-size: 24px;
  text-align: center;
  margin-bottom: 40px;
}

.next-button {
  padding: 10px 20px;
  font-size: 18px;
}
//
/* CSS for Bouncing Effect */
@keyframes pulse {
  0% {
    transform: scale(2);
  }
  50% {
    transform: scale(2.2);
  }
  100% {
    transform: scale(2);
  }
}
//
.blur-background {
  position: absolute;
  top: 0;
  object-fit: cover;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-image: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5));
  background-position: center;
  filter: blur(50px); /* Adjust the blur intensity as needed */
  opacity: 0.3; /* Adjust the opacity level as needed */
  z-index: 0;
}
/* Apply the animation to the buttons */
.pulse-button {
  animation: pulse 2s ;
}

.five {
  background: white;
  color: Black;
  border: green;
  box-shadow: #000000;
  border-radius: 5px;
  box-shadow: 0 3px 5px #777777;
}
.six {
  background: white;
  color: black;
  border: green;
  box-shadow: #000000;
  border-radius: 5px;
  box-shadow: 0 3px 5px #777777;
}
//

.circleTour, .circleTour::before {
  content: " ";
  margin: 15px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 auto;
  transition: all 0.3s;
  background-color: blue;
}

.circleTour::before {
  animation: mymove 2s infinite;
  position: absolute;
  background-color: blue
}

@keyframes mymove {
  50%   {
    transform: scale(2);
    opacity: 0
  }
  100%   {
    transform: scale(2);
    opacity: 0
  }
}
//
.stepper-ai {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
  margin-top: 5px;
  margin-left: 10px;
  border-radius: 12px;
  margin-right: 10px;
}

.step-ai {
  flex: 1;
  text-align: center;
  font-size: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 5px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid #7367f0;
  
}

.step-ai.active {
  background-color: #7367f0;
  color: #ffffff;
  transform: scale(1.1); /* Adjust the scale factor as needed for your zoom effect */
  transition: transform 0.3s ease;

}
//
.step-seven-ui {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
}

.preference-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.preference-item span {
  font-weight: bold;
}

.preference-item button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}
//

//card story-----------end
.scd-2 {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
  border-radius: 13px;
  cursor: pointer;
}
.active-category {
  border: 2px solid red; /* Add any border styles you want */
  transform: translateZ(10px); /* Add a 3D effect with translation */
  transition: transform 0.3s ease-in-out;
}
//
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
//
.zoom-in {
  /* Add your zoom-in CSS styles here */
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}
.scd-2:hover {
  opacity: 0.5;
}

.pm-0 {
  padding: 0;
  margin: 0;
}

.card-developer-meetup .meetup-img-wrapper {
  background-color: rgb(115 103 240 / 20%) !important;
}
//

.dashboard-task-complete-check {
  width: 100%;
  margin: 20px 0 0 0;
  border: 1px solid #dcdcdc73;
  padding: 12px 0 12px 40px;
  border-radius: 5px;
  font-weight: 500;
}

.dashboard-task-complete-progress {
  width: 90%;
  box-shadow: rgb(241 204 193) 0px 5px 15px 0px;
  border-radius: 10px;
  padding: 15px;
  position: absolute;
  top: 108px;
  z-index: 999;
  background-color: white;
}

#hidden {
  display: none !important;
}

.ecommerce-card {
  grid-template-columns: 1fr 2fr 1fr !important;
}

.grid-view .order4Members {
  order: 4;
  display: grid;
  font-weight: 500;
  grid-template-columns: 2fr 2fr !important;
}

.theme_shadow {
  box-shadow: 5px 8px 8px rgb(114 111 168 / 20%),
    -2px -2px 10px hsl(0deg 0% 100% / 20%) !important;
}

.theme_shadow2 {
  box-shadow: inset 5px 8px 8px rgb(114 111 168 / 20%),
    inset -2px -2px 10px rgb(255 255 255 / 20%) !important;
}

////////////////////////////////// custom button ////////////////////////////////////////
.c_btn {
  flex: 1 1 auto;
  margin: 10px;
  padding: 20px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
}

.c_btn_n {
  flex: 1 1 auto;
  padding: 11px 21px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
}

/* Demo Stuff End -> */

/* <- Magic Stuff Start */

.c_btn:hover,
.c_btn_n:hover {
  background-position: right center;
  /* change the direction of the change here */
}

.c_btn_1 {
  background-image: linear-gradient(
    to right,
    #f6d365 0%,
    #fda085 51%,
    #f6d365 100%
  );
}

.c_btn_1:active,
.c_btn_1:focus {
  background-image: linear-gradient(
    to right,
    #f6d365 0%,
    #fda085 51%,
    #f6d365 100%
  );
}

.c_btn_2 {
  background-image: linear-gradient(
    to right,
    #fbc2eb 0%,
    #a6c1ee 51%,
    #fbc2eb 100%
  );
}

.c_btn_2:active,
.c_btn_2:focus {
  background-image: linear-gradient(
    to right,
    #fbc2eb 0%,
    #a6c1ee 51%,
    #fbc2eb 100%
  );
}

.c_btn_3 {
  background-image: linear-gradient(
    to right,
    #84fab0 0%,
    #8fd3f4 51%,
    #84fab0 100%
  );
}

.c_btn_3:active,
.c_btn_3:focus {
  background-image: linear-gradient(
    to right,
    #84fab0 0%,
    #8fd3f4 51%,
    #84fab0 100%
  );
}

.c_btn_4 {
  background-image: linear-gradient(
    to right,
    #a1c4fd 0%,
    #c2e9fb 51%,
    #a1c4fd 100%
  );
}

.c_btn_4:active,
.c_btn_4:focus {
  background-image: linear-gradient(
    to right,
    #a1c4fd 0%,
    #c2e9fb 51%,
    #a1c4fd 100%
  );
}

.c_btn_5 {
  background-image: linear-gradient(
    to right,
    #ffecd2 0%,
    #fcb69f 51%,
    #ffecd2 100%
  );
}

.c_btn_5:active,
.c_btn_5:focus {
  background-image: linear-gradient(
    to right,
    #ffecd2 0%,
    #fcb69f 51%,
    #ffecd2 100%
  );
}

/* Magic Stuff End -> */

//////////////////////////////////////// custom button ////////////////////////////////////////////////////
// footer.footer {
//   position: fixed !important;
//   left: 0;
//   bottom: 0 !important;
// }

.list-view .order4Members {
  order: 2;
  display: grid;
  font-weight: 500;
  grid-template-columns: 1fr 2fr !important;
}

.grid-view .ecommerce-card .item-description {
  white-space: unset !important;
  height: 89px;
}

.list-view .ecommerce-card .item-description {
  height: 42px;
}

.ecommerce-application .grid-view .ecommerce-card .item-img {
  min-height: 9.85rem !important;
}

.grid-view .cardBody-1-challenge-list {
  padding: 0 14px !important;
}

.grid-view .cardBody-2-challenge-list {
  padding-top: 0 !important;
}

.grid-view .challenge-list-btn-div {
  display: flex;
}

.list-view .challenge-list-btn-div {
  margin: auto;
}

.list-view .iDidIt-btn {
  margin-bottom: 14px;
}

.addChallengeImageName {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 120px;
}
.flex-1 {
  flex: 1 !important;
}
.flex-05 {
  flex: 0.5 !important;
}
.flex-0 {
  flex: 0 !important;
}

.flex-basis-30 {
  flex-basis: 30%;
}
.flex-basis-50 {
  flex-basis: 50%;
}
.flex-basis-70 {
  flex-basis: 70%;
}

.patient-activity-card-container {
  height: 400px;
  overflow-x: hidden;
  margin-bottom: 20px;
}

.m-3px {
  margin: 3px !important;
  // padding: 0.6rem 0.6rem;
}

.modal-cross {
  position: absolute;
  right: 0;
  z-index: 1;
}

.pd-b-0 {
  padding-bottom: 0 !important;
}

.c-badge {
  padding: 10px;
  border-radius: 100px;
  margin: 5px;
}

.c-badge i,
.c-badge svg {
  height: 15px !important;
  width: 15px !important;
}

.c-transition:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%);
  transition: all 0.3s ease-in-out, background 0s, color 0s, border-color 0s;
}

.animatedBtn1 {
  background: rgb(14, 25, 162);
  color: rgb(255, 255, 255);
  padding: 2rem 4rem;
  border: 0px;
  border-radius: 0px;
  cursor: pointer;
  font-size: 1.2em;
}

.list-view .s-add-button {
  justify-content: flex-start !important;
}

.c-table-img {
  margin: 5px;
  width: 100%;
  height: 80px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/////////// gallery modal //////////
.gallery_image_ul {
  list-style-type: none;
}

.gallery_image_ul li {
  display: inline-block;
}

.gallery_image_ul li input[type="radio"][id^="cb"] {
  display: none;
}

.mainLogo {
  width: 140px;
  position: relative;
  top: -10px;
}

.mainLogoVertical {
  width: 140px;
  position: relative;
  top: -22px;
}

.option_main_div_outter .option_main_div input[type="radio"][id^="cb_tOpt"] {
  display: none;
}

// .option_main_div_outter .option_main_div input[type="radio"][id^="cb_tOpt"]:checked + label .que-card-m {
//   border: 1px solid #2f92f8;
// }

.option_main_div_outter .option_main_div input[type="checkbox"][id^="cb_tOpt"] {
  display: none;
}

// .option_main_div_outter .option_main_div input[type="checkbox"][id^="cb_tOpt"]:checked + label .que-card-m {
//   border: 1px solid #2f92f8;
// }

.gallery_image_ul li label {
  border: 1px solid #fff;
  padding: 10px;
  display: block;
  position: relative;
  margin: 10px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.gallery_image_ul li label::before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
}

.gallery_image_ul li label img {
  height: 100px;
  width: 100px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

.gallery_image_ul li :checked + label {
  border-color: #ddd;
}

.gallery_image_ul li :checked + label::before {
  content: "✓";
  background-color: #7367f0;
  transform: scale(1);
}

.gallery_image_ul li :checked + label img {
  transform: scale(0.9);
  box-shadow: 0 0 5px #7367f0;
  z-index: -1;
}

.dotted-card-heading {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 50px;
}

.h-60-px {
  height: 60px !important;
}

.list-view .action-buttons {
  justify-content: flex-end !important;
  align-items: flex-start;
}

.list-view .action-buttons button {
  margin-left: 5px;
  margin-right: 5px;
}

.grid-view .action-buttons {
  padding: 0px 10px;
}

.grid-view .action-buttons button {
  width: 48%;
  margin: 0 5px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.section-cats-right-card {
  transition: all 0.3s ease-in-out, background 0s, color 0s, border-color 0s;
}

.section-cats-right-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%);
}

.custom-badge {
  padding: 10px 15px 10px 15px;
}

.m-w75 {
  min-width: 75% !important;
}

.patientListDataTable .hkMDrI {
  overflow: inherit !important;
}

.assignmentsListDataTable div {
  white-space: normal !important;
}

.list-view .randomClass {
  display: flex;
}

.list-view .section-card-container {
  grid-template-columns: 1fr 3fr !important;
}

.list-view .section-card-buttons {
  display: flex !important;
}

.list-view .section-card-body {
  border-right: none !important;
  justify-content: center;
}

.sections-button {
  cursor: pointer;
  margin: 2px;
  flex-wrap: nowrap;
}

.grid-view .sections-button,
.grid-view .sections-button button {
  display: block;
  width: 100%;
}

.grid-view .randomClass {
  width: 100%;
}
.line-separator {
  border-bottom: 1px solid #eee;
  margin: 10px 0;
}

.text-right {
  text-align: right;
}

.section-right-card {
  position: fixed;
  bottom: 0;
  right: 0;
  top: 200px;
  // left: 0;
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 410px;
}

.grid-view .sections-button {
  width: 100%;
}

.test-options-picker-image {
  width: 100%;
  height: 60px;
}

.cursor-pointer {
  cursor: pointer;
}

.t-bx-shadow {
  transform: translateY(-5px);
  box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%);
}

.test-card-m:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%) !important;
}

.test-card-m {
  cursor: pointer;
  height: 458px;
}

.que-card-m {
  cursor: pointer;
  padding: 15px;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  min-height: 100px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.que-card-m:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%) !important;
  transition: all 0.3s ease-in-out, background 0s, color 0s, border-color 0s;
}

.q-text {
  margin: 30px auto;
  width: 600px;
  text-align: center;
  background: linear-gradient(135deg, #ffffff 0%, #ececec 100%);
  border-radius: 50px;
  padding: 33px 68px;
}

.test-btn-m:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%) !important;
}

.test_d_btn {
  margin: 20px;
  float: right;
}

.meeting_tab_m {
  background-color: #7367f0;
  padding: 15px;
  box-shadow: 0 4px 7px 0 rgb(0 65 248 / 57%);
}

.nav_a_custom {
  color: white !important;
}

.nav_a_custom.active {
  background-color: white !important;
  color: #7367f0 !important;
}

.class_table_img {
  margin: 10px;
  width: 70px;
  height: 70px;
  background-size: contain;
  background-repeat: no-repeat;
}

// Bilal
.bg-landingpge {
  background: linear-gradient(207.38deg, #5e70ce -1.82%, #3fc282 88.91%);
}

.text-center {
  text-align: center !important;
}

.img-size145 {
  width: 145px;
}

.img-size300 {
  width: 300px;
}

.mb-25- {
  margin-bottom: -25px;
}

.pb-20 {
  padding-bottom: 20px;
}

.p-all-20 {
  padding: 20px;
}

.p-all-0 {
  padding: 0px !important;
}

.img-100 {
  width: 100%;
}

.img-73 {
  width: 73%;
}

.img-75 {
  width: 75% !important;
}

.img-100px {
  width: 100px;
}

.border-radius-40 {
  border-radius: 40px !important;
}
.border-radius-10 {
  border-radius: 10px !important;
}
.border-radius-5 {
  border-radius: 5px !important;
}
.border-radius-14 {
  border-radius: 14px !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border-radius-85 {
  border-radius: 85px;
}

.full-md-section-inner {
  position: absolute;
  top: 86px;
  left: 105px;
  width: 429px;
}

.positon-relative {
  position: relative !important;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-16 {
  font-size: 16px;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-3 {
  font-weight: 300;
}

.text-white {
  color: white;
}

.trening-section-box {
  font-size: 18px;
  line-height: 27px;
  margin: 12px 0 9px;
}

.color-light-gry {
  color: #e5e5e5;
}

.mt-8 {
  margin-top: 80px !important;
}

.download-apps {
  width: 161px;
  margin: 29px 11px 0 0px;
}

.display-block {
  display: block !important;
}

.hover-lookingFor {
  position: absolute;
  top: 2px;
  width: 100% !important;
  height: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #2b2525 100%);
  display: flex;
  color: #ffffff;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  transition: all 0.5s ease-in;
  border-radius: 10px;
}

.hover-lookingFor:hover {
  opacity: 1;
}

.p-25 {
  padding: 2.5rem !important;
}

@include media-breakpoint-down(sm) {
  .full-md-section-inner {
    text-align: center;
    left: 0px !important;
    position: relative !important;
    top: 0px !important;
  }
  .img-73 {
    display: none !important;
  }
  .img-75 {
    width: 100% !important;
  }
  .sm-text-center {
    text-align: center !important;
  }

  .img-70 {
    width: 100% !important;
  }
}

.bg-white {
  background-color: white;
}

.grid-view.front-sections {
  grid-template-columns: 1fr 1fr 1fr 1fr !important;
}

.list-view.front-sections {
  grid-template-columns: 1fr !important;
}

.nav.navbar-nav {
  justify-content: center;
}

.height220 {
  height: 220px;
}
.height250 {
  height: 250px;
}
.min-height350 {
  min-height: 350px;
}
.overview-offcanvas-bottom {
  height: 70vh;
}
.btrr0 {
  border-top-right-radius: 0 !important;
}
.bbrr0 {
  border-bottom-right-radius: 0 !important;
}
.class-content-card {
  grid-template-columns: 2fr 2fr 1.2fr !important;
}
.gtc-1 {
  grid-template-columns: 1fr !important;
}
.discovery-tooltip {
  background-color: "#FFF";
  height: 400;
  overflow: "hidden";
  overflow-y: "scroll";
  min-width: 300;
}
.discovery-tooltip::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.card-transition:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%);
}

// Bilal
.p-img-outer {
  padding: 34.5px 0 !important;
}
.line-height-170 {
  line-height: 170%;
}
.margin-p {
  margin: 26px 0 23px 0;
}
.width-100 {
  width: 100% !important;
}
.width-85 {
  width: 85% !important;
}
.height-100 {
  height: 100% !important;
}
.height-50 {
  height: 50% !important;
}
.jc-c {
  justify-content: center !important;
}
.img-70 {
  width: 70%;
}
.w-85 {
  width: 85px;
}
.w-120 {
  width: 120px;
}
.footer-ul {
  font-size: 16px !important;
  line-height: 210% !important;
  color: #3f3d56 !important;
}
.li-style {
  list-style: none !important;
}
.ft-social {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin: 0 0 24px 0;
  color: #152c5b;
}
.li-style-2 {
  display: inline-block !important;
}
.footer-ul-3 {
  margin: 0 16px;
}
.pl-0 {
  padding-left: 0px !important;
}
.mt-05 {
  margin-top: 5px !important;
}
.captcha-container {
  width: 80%;
  padding: 8px;
  background: #eee;
  text-align: center;
  align-items: center;
  justify-content: center;
}
// Bilal About Founder Page
.founder-header {
  font-weight: bold;
  font-size: 42px;
  line-height: 63px;
  color: #ffffff;
  padding: 80px !important;
}
.about-founder-header {
  font-weight: bold;
  font-size: 30px;
  line-height: 54px;
  margin: 0 0 18px 0;
}
.about-founder-header-p {
  font-size: 18px;
  line-height: 35px;
  margin: 0 0 36px 0;
}
.about-author-header-p {
  font-size: 18px;
  line-height: 35px;
}
.mg-0 {
  margin-bottom: 0px !important;
}

.left-20 {
  left: 20px !important;
}
.searchBar {
  position: absolute;
  background-color: #ffffff;
  padding: 20px;
  z-index: 999;
  max-height: 450px;
  height: auto;
  list-style-type: none;
  border-radius: 0.5rem;
  margin-top: 00.5rem;
  box-shadow: 0 15px 30px 0 rgb(0 0 0 / 11%), 0 5px 15px 0 rgb(0 0 0 / 8%);
  overflow: auto;
}
.searchNavBar {
  position: absolute;
  background-color: #ffffff;
  padding: 20px;
  width: 100%;
  z-index: 999;
  max-height: 450px;
  height: auto;
  list-style-type: none;
  border-radius: 0.5rem;
  margin-top: 00.5rem;
  box-shadow: 0 15px 30px 0 rgb(0 0 0 / 11%), 0 5px 15px 0 rgb(0 0 0 / 8%);
  overflow: auto;
}
.mt-30 {
  margin-top: 30px !important;
}
.height-260 {
  height: 260px !important;
}
.section-class {
  padding: 0px;
  padding-top: 10px;
}
.section-class:hover {
  background-color: #e3e1fc;
}
.dragTr:hover {
  background-color: #fbfbfb;
  box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 17%);
  cursor: pointer;
}
.youtubeIfram div Iframe {
  height: 300px !important;
  width: 50% !important;
}
.reviews {
  width: 7%;
  border-radius: 10px;
}
.display-flex {
  display: flex;
}
.package-detail {
  text-align: left;
  font-size: 15px;
  line-height: 30px;
  display: flex;
  justify-content: center;
  margin-top: 14px;
}
.btcolor {
  background-color: none;
}
.btcolor:hover {
  background-color: #7367f0 !important ;
  color: white !important;
}

.hv-20 {
  height: 20vh !important;
}
.hv-25 {
  height: 25vh !important;
}
.hv-50 {
  height: 50vh !important;
}
.hv-75 {
  height: 75vh !important;
}
.hv-100 {
  height: 100vh !important;
}
.table-content-scrollable {
  overflow-y: scroll;
}

.yz-widget .yz-copyright {
  display: none !important;
}
.faceBookStyle {
  height: 43px;
  width: 87px;
  border-radius: 4px;
  background: #3b5998;
  color: white;
  border: 0px transparent;
  text-align: center;
  display: inline-block;
}
.height-45 {
  height: 45px;
}
.border-primary {
  border: 1px solid #7367f0 !important;
}
.padding-block-30 {
  padding-block: 30px;
}
// -----------step Wizerd--------------
.md-stepper-horizontal {
	display:table;
	width:100%;
	margin:0 auto;
	background-color:#FFFFFF;
	box-shadow: 0 3px 8px -6px rgba(0,0,0,.50);
}
.md-stepper-horizontal .md-step {
	display:table-cell;
	position:relative;
	padding:24px;
}
.md-stepper-horizontal .md-step:hover,
.md-stepper-horizontal .md-step:active {
	background-color:rgba(0,0,0,0.04);
}
.md-stepper-horizontal .md-step:active {
	border-radius: 15% / 75%;
}
.md-stepper-horizontal .md-step:first-child:active {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.md-stepper-horizontal .md-step:last-child:active {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.md-stepper-horizontal .md-step:hover .md-step-circle {
	background-color:#757575;
}
.md-stepper-horizontal .md-step:first-child .md-step-bar-left,
.md-stepper-horizontal .md-step:last-child .md-step-bar-right {
	display:none;
}
.md-stepper-horizontal .md-step .md-step-circle {
	width:30px;
	height:30px;
	margin:0 auto;
	background-color:#999999;
	border-radius: 50%;
	text-align: center;
	line-height:30px;
	font-size: 16px;
	font-weight: 600;
	color:#FFFFFF;
}
.md-stepper-horizontal.green .md-step.active .md-step-circle {
	background-color:#00AE4D;
}
.md-stepper-horizontal.orange .md-step.active .md-step-circle {
	// background-color:#F96302;
  background-color: #7367f0 ;
}
.md-stepper-horizontal .md-step.active .md-step-circle {
	background-color: rgb(33,150,243);
}
.md-stepper-horizontal .md-step.done .md-step-circle:before {
	font-family:'FontAwesome';
	font-weight:100;
	content: "\f00c";
}
.md-stepper-horizontal .md-step.done .md-step-circle *,
.md-stepper-horizontal .md-step.editable .md-step-circle * {
	display:none;
}
.md-stepper-horizontal .md-step.editable .md-step-circle {
	-moz-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
}
.md-stepper-horizontal .md-step.editable .md-step-circle:before {
	font-family:'FontAwesome';
	font-weight:100;
	content: "\f040";
}
.md-stepper-horizontal .md-step .md-step-title {
	margin-top:16px;
	font-size:16px;
	font-weight:600;
}
.md-stepper-horizontal .md-step .md-step-title,
.md-stepper-horizontal .md-step .md-step-optional {
	text-align: center;
	color:rgba(0,0,0,.26);
}
.md-stepper-horizontal .md-step.active .md-step-title {
	font-weight: 600;
	color:rgba(0,0,0,.87);
}
.md-stepper-horizontal .md-step.active.done .md-step-title,
.md-stepper-horizontal .md-step.active.editable .md-step-title {
	font-weight:600;
}
.md-stepper-horizontal .md-step .md-step-optional {
	font-size:12px;
}
.md-stepper-horizontal .md-step.active .md-step-optional {
	color:rgba(0,0,0,.54);
}
.md-stepper-horizontal .md-step .md-step-bar-left,
.md-stepper-horizontal .md-step .md-step-bar-right {
	position:absolute;
	top:36px;
	height:1px;
	border-top:1px solid #DDDDDD;
}
.md-stepper-horizontal .md-step .md-step-bar-right {
	right:0;
	left:50%;
	margin-left:20px;
}
.md-stepper-horizontal .md-step .md-step-bar-left {
	left:0;
	right:50%;
	margin-right:20px;
}
.orange-clr {
  background-color:#F96302 !important
}
.inputBorderStyle {
  width: 25%;
  border: none;
  border-bottom: 2px solid;
  border-radius: 0px;
  border-color: lightgray
}
.over-flow-hidden {
  overflow: hidden !important;
}
.font-size-14 {
  font-size: 14px !important;
}
.jc-sb {
  justify-content: space-between;
}
.jc-sa {
  justify-content: space-around;
}
.p-05 {
  padding: 5px ;
}
.align-item-center {
  align-items: center;
}
.resources-download {
  background-color: white;
    border-radius: 8px;
    box-shadow: 34.2999px 74.4817px 157px rgba(102, 101, 101, 0.08), 22.2314px 48.2752px 91.9468px rgba(102, 101, 101, 0.0607407), 13.2118px 28.6892px 50.0074px rgba(102, 101, 101, 0.0485926), 6.85998px 14.8963px 25.5125px rgba(102, 101, 101, 0.04), 2.7948px 6.06888px 12.7926px rgba(102, 101, 101, 0.0314074), 0.635183px 1.37929px 6.17824px rgba(102, 101, 101, 0.0192593);
}
.resources-download:hover {
  transform: scale(1.02);
}
.custom-button-icon {
  border: 1px solid blue;
  border-color: blue;
  border-radius: 3px;
  cursor: pointer;
  background-color: white;
  font-size: 14px;
  width: 40%;
  padding-inline: 20px;
  padding-block: 4px;
  color: blue;
  display: flex;
  justify-content: center;
}
.custom-buttom-inner {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80%;
}
.custom-input {
  border-bottom: 1px solid #d8d6de;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  width: 50%;
}
.author-logo {
    background-color: lightgray;
    border-radius: 22px;
    padding-block: 10px;
    padding-inline: 12px;
}
.add-image-class {
  background-color: lightgray;
  border-radius: 10px;
}
.add-video-class {
  margin-top: -40px;
  display: flex;
  justify-content: center;
  background-color: lightgray;
  padding: 50px;
  align-items: center;
}
.remaing-lessing-header {
  justify-content: flex-end;
  display: flex;
  padding: 5px;
  background-color: white;
  border-radius: 3px;
  cursor: pointer;
}
.remaing-content-list {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  padding: 5px;
  background-color: white;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.class-header-positions {
  position: absolute;
  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
}
.class-Video-positions {
  position: absolute;
  top: 110px;
  left: 0;
  right: 0;
  text-align: center;
}
.class-lesson-content-positions {
  position: absolute;
  top: 50px;
  bottom: 0;
  left: 0;
  right: 0;
}


/////////////////////////////////////////////////////////
.mainTopSliderDisc {
  margin-right: -40px !important;
}

.scrollDiv::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.scrollDiv::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.scrollDiv::-webkit-scrollbar-thumb
{
	background-color: #000000;
}
.oppDiv {
  position: absolute;
  width: 931px;
  height: 450px;
  opacity: 0.4;
  border-radius: 5px;
}
.oppDiv2 {
    position: absolute;
    opacity: 0.4;
    margin-bottom: 10px;
    border-radius: 5px;
}
.rmedcattop {
    background: linear-gradient(108.46deg, rgb(163 45 181) 0%, rgb(3 9 36) 100%);
    margin: 0 -21px;
    padding: 10px 0;
}
.rmedplaybtn {
    padding: 10px 10px;
    background-color: #0080ff;
    color: white;
    border-radius: 50px;
    margin-right: 20px;
}
.height-500 {
  height: 500px;
}
.position-absolute-center {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
// .modal {
//   position: fixed;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   width: 300px; /* Adjust the width to your preference */
//   background-color: white;
//   box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
//   z-index: 9999;
//   transform: translateX(100%);
//   transition: transform 0.3s ease-in-out;
// }

// .modal-content {
//   padding: 20px;
// }

// .modal.active {
//   transform: translateX(0);
// }
/* Apply styles to the entire dropdown */
.custom-dropdown {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #d8d6de;
  padding: 8px;
  color: #d8d6de;
  transition: border-color 0.3s;
  /* Add more custom styles here */
}

.custom-dropdown:focus {
  border-color: blue;
}
// /* Style the dropdown options */
// .custom-dropdown select {
//   /* Add styles for the dropdown options here */
//   borw
// }

/* Style the dropdown arrow icon */
.custom-dropdown .arrow {
  /* Add styles for the dropdown arrow icon here */
  font-size: 25px; 
}
.custom-select .css-yk16xz-control::before {
  content: none;
}
.custom-phone-input {
  border: 1px solid lightgray;
  padding: 7px;
  border-radius: 5px;
  /* Add any other styles you want here */
}
.custom-phone-input input {
  border: none; /* Hide the border */
  outline: none; /* Hide the focus outline */
  /* Add any other styles you want here */
}

/* Target the inner input element when focused */
.custom-phone-input input:focus {
  /* Customize the focus styles here */
  border: none; /* Hide the border */
  outline: none; /* Hide the focus outline */
}
.inputBoxInDropDown {
  border: 1px solid lightgray;
    margin-top: 7px;
    border-radius: 5px;
    position: absolute;
    background-color: white;
    width: 88%;
    z-index: 10;
    display: flex;
    flex-direction: column;
}
.textSpan {
  padding-block: 12px;
  padding-inline: 10px;
  cursor: pointer;
}
.textSpan:hover {
  background-color: #a0c4ff;
}
.worningError {
  border: 1px solid red !important;
  border-radius: 4px !important;
}
.custom-phone-input input {
  background-color: transparent !important; /* Use !important to override existing styles */
}